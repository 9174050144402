import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../layouts/layout';
import SocialShareButtons from '../components/socialShareButtons';
import MetaTags from '../components/SiteMeta';

export const BlogDetailStyled = styled.section`
  ${tw`block relative`}

  .content-section {
    ${tw`mb-28 xl:mb-36 mt-14 md:mt-20 xl:mt-28 relative`}

    .social-share-wrp {
      ${tw`absolute top-0`}
      z-index: 10;
      left: -30px;

      .share-items {
        ${tw`flex flex-col items-center justify-center bg-white py-3 md:py-8`}
        width: 27px;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 31px;

        @media (min-width: 768px) {
          width: 62px;
        }

        .share-item {
          ${tw`mb-7`}

          &:last-child {
            ${tw`mb-0`}
          }

          a {
            ${tw`block`}

            &:hover {
              ${tw`opacity-70`}
            }
          }

          &.facebook {
            svg {
              width: 9px;

              @media (min-width: 768px) {
                width: auto;
              }
            }
          }

          &.twitter {
            svg {
              width: 11px;

              @media (min-width: 768px) {
                width: auto;
              }
            }
          }

          &.linkedin {
            svg {
              width: 10px;

              @media (min-width: 768px) {
                width: auto;
              }
            }
          }
        }
      }
    }

    .title-wrp {
      ${tw`text-center mb-16 xl:mb-20`}

      h2 {
        ${tw`text-4xl md:text-6xl mb-2 xl:mb-4 text-primary-dark font-bold mx-auto`}
        line-height: 40px;
        max-width: 964px;

        @media (min-width: 768px) {
          line-height: 60px;
        }
      }

      .date {
        ${tw`md:text-xl lg:text-2xl`}
      }
    }
    .content-detail-section {
      figure {
        width: calc(100% + 72px);
        max-width: initial;
        margin-left: -36px;
        ${tw`my-12 xl:my-16`}

        @media (min-width: 1280px) {
          ${tw`ml-0`}
          width: 100%;
        }

        span {
          width: 100% !important;
        }
      }

      p {
        ${tw`mb-7 leading-7 md:leading-9 text-gray-dark md:text-xl`}
      }

      h4 {
        ${tw`mb-7 text-primary-dark text-xl font-bold`}
      }

      ul {
        ${tw`text-gray-dark`}

        li {
          ${tw`md:text-lg leading-7 md:leading-9 mb-5 relative pl-7`}

          &:before {
            ${tw`absolute left-0 block bg-primary-dark top-2 md:top-3`}
            content: '';
            z-index: 1;
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
        }
      }

      ol {
        ${tw`text-gray-dark`}
        list-style: none;
        counter-reset: my-awesome-counter;

        li {
          counter-increment: my-awesome-counter;
          ${tw`md:text-lg leading-7 md:leading-9 mb-5 relative pl-7`}

          &:before {
            ${tw`absolute left-0 block font-bold text-primary-dark`}
            content: counter(my-awesome-counter) '. ';
          }
        }
      }

      > * :not(figure) {
        ${tw`xl:px-24`}
      }

      a {
        ${tw`text-primary`}

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .blog-detail {
    ${tw`p-0`}

    .heading-wrp {
      ${tw`items-start`}

      h2 {
        ${tw`mb-6`}
      }
    }
  }
`;

export default function NewsDetail({ data }) {
  const { content, date, title } = data?.allWpNewsItem?.nodes[0];
  const { acfGlobalContent } = data?.allWpNewsItem?.nodes[0];
  let postUrl;
  if (typeof window !== 'undefined') {
    postUrl = encodeURI(window.location.href);
  }
  return (
    <Layout preFooterContent={acfGlobalContent}>
      <MetaTags
        title={acfGlobalContent.metaTitle}
        description={acfGlobalContent.metaDescription}
        image={acfGlobalContent.metaOgImage?.sourceUrl}
      />
      <main>
        <BlogDetailStyled className="ves-section">
          <div className="ves-container">
            <div className="content-section">
              <SocialShareButtons url={postUrl} title={title} />

              <div className="title-wrp">
                <h2>{title}</h2>
                <span className="date">{date}</span>
              </div>
              {/* eslint-disable react/no-danger */}
              {content && (
                <div
                  className="content-detail-section"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
            </div>
          </div>
        </BlogDetailStyled>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    allWpNewsItem(sort: { order: DESC, fields: date }, filter: { id: { eq: $id } }) {
      nodes {
        title
        date(formatString: "MMMM DD, YYYY")
        content
        acfGlobalContent {
          prefooterTitle
          prefooterButtonText
          prefooterButtonLink {
            url
          }
          metaTitle
          metaDescription
          metaOgImage {
            sourceUrl
          }
        }
      }
    }
  }
`;

NewsDetail.propTypes = {
  data: PropTypes.shape({
    allWpNewsItem: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.any),
    }),
  }).isRequired,
};
