import React from 'react';
import PropTypes from 'prop-types';

import Facebook from '../../images/facebook-f.svg';
import Twitter from '../../images/twitter.svg';
import LinkedIn from '../../images/linkedin-in.svg';

export default function SocialShareButtons({ url, title }) {
  /*

  Social Share Links

  Facebook:
  https://www.facebook.com/sharer.php?u=[post-url]

  Twitter:
  https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]

  LinkedIn:
  https://www.linkedin.com/shareArticle?url=[post-url]&title=[post-title]

  */

  const shareButtonPopup = (media) => {
    if (typeof window === 'undefined') {
      return null;
    }
    switch (media) {
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer.php?u=${url}&title=${title}`,
          '_blank',
          'status=0,title=0,height=600,width=800,scrollbars=1',
        );

        break;
      case 'twitter':
        window.open(
          `https://twitter.com/share?url=${url}&text=${title}`,
          '_blank',
          'status=0,title=0,height=600,width=800,scrollbars=1',
        );

        break;
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/shareArticle?url=${url}&title=${title}`,
          '_blank',
          'status=0,title=0,height=600,width=800,scrollbars=1',
        );

        break;
      default:
        return '';
    }

    return '';
  };

  return (
    <>
      <div className="social-share-wrp">
        <ul className="share-items">
          <li className="share-item facebook">
            <a href="#12" className="ves-transition" onClick={() => shareButtonPopup('facebook')}>
              <Facebook />
            </a>
          </li>
          <li className="share-item twitter">
            <a href="#12" className="ves-transition" onClick={() => shareButtonPopup('twitter')}>
              <Twitter />
            </a>
          </li>
          <li className="share-item linkedin">
            <a href="#12" className="ves-transition" onClick={() => shareButtonPopup('linkedin')}>
              <LinkedIn />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

SocialShareButtons.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};
